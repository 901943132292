<template>
  <div class="page-content form-page">
    <Header close-icon />

    <div class="content-title text-center">{{ pageLabel }}</div>

    <div class="container">
      <div class="form-container">
        <Form ref="resetPasswordForm" class="content-form" v-slot="{ errors }">
          <div class="field field--input">
            <button class="field-input-button"
                    type="button"
                    @click.stop="isPasswordVisible = !isPasswordVisible"
            >
              <img
                height="20"
                width="20"
                :src="isPasswordVisible ? require('@/assets/images/icon-password-no-view.svg') : require('@/assets/images/icon-password-view.svg')"
                alt="Show"
              />
            </button>
            <Field
              v-model="password"
              :type="isPasswordVisible ? 'text' : 'password'"
              name="password"
              class="form-control"
              readonly
              @focus="removeReadOnly"
              @input="fieldsChanged = true"
              rules="requiredPass|strongPass"
              :placeholder="$t('placeholder.enterPassword')"
            >
            </Field>
          </div>

          <div class="field field--input">
            <button class="field-input-button"
                    type="button"
                    @click.stop="isPasswordVisible = !isPasswordVisible"
            >
              <img
                height="20"
                width="20"
                :src="isPasswordVisible ? require('@/assets/images/icon-password-no-view.svg') : require('@/assets/images/icon-password-view.svg')"
                alt="Show"
              />
            </button>
            <Field
              v-model="confirmPassword"
              :type="isPasswordVisible ? 'text' : 'password'"
              class="form-control"
              readonly
              @focus="removeReadOnly"
              @input="fieldsChanged = true"
              name="confirmPassword"
              rules="requiredPassCurrent|confirmed:@password"
              :placeholder="$t('placeholder.confirmPassword')"
            >
            </Field>
          </div>

          <div class="btn-outer">
            <button
              @click.prevent="resetPassword"
              type="button"
              class="btn btn--secondary"
              :disabled="isDataProcessing"
            >
              {{ pageLabel }}
            </button>
          </div>

          <ul v-if="password"
              class="password-validation">
            <li class="password-validation__option"
                :class="{'password-validation__option_success': passHasMinLength}"
            >
              <img v-if="passHasMinLength"
                   :src="require('@/assets/images/icon-check.svg')"
                   class="password-validation__icon"
                   alt="check"
              >
              {{ $t('text.passwordValidation.characters') }}
            </li>
            <li class="password-validation__option"
                :class="{'password-validation__option_success': passContainsCapitalLetters}"
            >
              <img v-if="passContainsCapitalLetters"
                   :src="require('@/assets/images/icon-check.svg')"
                   class="password-validation__icon"
                   alt="check"
              >
              {{ $t('text.passwordValidation.capitalLetter') }}
            </li>
            <li class="password-validation__option"
                :class="{'password-validation__option_success': passContainsNumbers}"
            >
              <img v-if="passContainsNumbers"
                   :src="require('@/assets/images/icon-check.svg')"
                   class="password-validation__icon"
                   alt="check"
              >
              {{ $t('text.passwordValidation.number') }}
            </li>
            <li v-if="confirmPassword"
                class="password-validation__option"
                :class="{'password-validation__option_success': passEqualsConfirmPass}"
            >
              <img v-if="passEqualsConfirmPass"
                   :src="require('@/assets/images/icon-check.svg')"
                   class="password-validation__icon"
                   alt="check"
              >
              {{ $t('text.passwordValidation.match') }}
            </li>
          </ul>

          <template v-if="!fieldsChanged">
            <div v-if="Object.keys(errors).length" class="error text-center">
              {{ errors.password || errors.confirmPassword }}
            </div>

            <div v-else-if="errorMessage" class="error text-center">
              {{ errorMessage }}
            </div>
          </template>
        </Form>

        <UiPreloaderContainer
            v-if="isDataProcessing"
            class="bg-white bg-white--transparent"
            absolute
        >
          <UiPreloader />
        </UiPreloaderContainer>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field } from "vee-validate";
import Header from "@/components/Header.vue";
import axios from "axios";
import helpers from "@/helpers";
import UiPreloaderContainer from "@/components/ui-components/preloader/UiPreloaderContainer.vue";
import UiPreloader from "@/components/ui-components/preloader/UiPreloader.vue";

export default {
  name: "ResetPassword",
  components: {
    Form,
    Field,
    Header,
    UiPreloaderContainer,
    UiPreloader,
  },
  data() {
    return {
      confirmPassword: null,
      password: null,
      errorMessage: null,
      fieldsChanged: false,
      isPasswordVisible: false,
      isDataProcessing: false
    };
  },
  computed: {
    pageLabel() {
      const pageName = this.$router.currentRoute._value.name;

      return this.$t("pageLabel." + pageName);
    },
    passHasMinLength() {
      return this.password.length >= 8;
    },
    passContainsCapitalLetters() {
      return /[A-Z]/.test(this.password);
    },
    passContainsNumbers() {
      return /\d/.test(this.password);
    },
    passEqualsConfirmPass() {
      return this.password === this.confirmPassword;
    },
    isPasswordValid() {
      return this.passHasMinLength && this.passContainsCapitalLetters && this.passContainsNumbers && this.passEqualsConfirmPass;
    },
  },
  methods: {
    removeReadOnly(event) {
      event.preventDefault();
      if (event.target.getAttribute("readonly") === "") {
        event.target.removeAttribute("readonly");
      }
    },
    async resetPassword() {
      this.fieldsChanged = false;

      try {
        const { valid } = await this.$refs.resetPasswordForm.validate();
        if (valid && this.isPasswordValid) {
          this.isDataProcessing = true;
          await axios.post("/auth/resetPassword", {
            password: this.password,
            confirmPassword: this.confirmPassword,
            token: this.$route.query.token
          });
          const { data } = await axios.post("auth/login/token", {
            resetToken: this.$route.query.token
          });
          localStorage.setItem("auth-data", JSON.stringify(data));
          localStorage.setItem("need-to-reload", "true");
          localStorage.setItem("first-login", "true");
          await this.redirectAfterLogin(data);
        }
      } catch (error) {
        this.isDataProcessing = false;
        this.errorMessage = error.response.data.message;
        if (error.response.status === 422)
          this.$router.push({ name: "sign-in-page" });
      }
    },
    async hrSystemEntrance(systems, token) {
      const redirectUrl = await helpers.generateToken(systems.data[0], token);
      localStorage.setItem("hr-system-entrance", "true");
      //@ts-ignore
      window.location.replace(redirectUrl);
    },
    async redirectAfterLogin(user) {
      const token = user.token;
      const systems = await helpers.loadSystems(token);
      this.isDataProcessing = false;

      if (this.$route.name === "invite") {
        return this.hrSystemEntrance(systems, token);
      }

      if (!systems.data.length) {
        localStorage.setItem("utm_source", "");
        localStorage.setItem("utm_medium", "");
        localStorage.setItem("utm_campaign", "");

        localStorage.setItem("emptySystemsList", "true");

        if (systems.data.code === 76) { // CODE_NOT_FINISHED_REGISTRATION_NAME
          document.location.replace(window.location.origin + "/gifting-platform#/sign-up/confirm-data");
        }
        if (systems.data.code === 77) { // CODE_NOT_FINISHED_REGISTRATION
          document.location.replace(window.location.origin + "/gifting-platform#/create-hr-system");
        }
        return;
      }

      if (!user.user.emailVerified) {
        window.location.replace(
          window.location.origin + "/gifting-platform#/dashboard"
        );
      }

      if (systems.data.length > 1) {
        window.location.replace(
          window.location.origin + "/gifting-platform#/dashboard"
        );
      } else {
        await this.hrSystemEntrance(systems, token);
      }
    }
  },
  mounted() {
    if (!this.$route.query.token || this.$route.query.token === "") {
      this.$router.push({ name: "sign-in-page" });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";

.field {
  position: relative;

  .field-input-button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0 17px 0 5px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}

.password-validation {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin: 15px 0;
  &__option {
    position: relative;
    padding-left: 25px;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: $color-black;
    &_success {
      color: $color-success-green;
    }
  }
  &__icon {
    position: absolute;
    top: calc(50% - 10px);
    left: 0;
    height: 20px;
    width: 20px;
    display: block;
    object-fit: contain;
  }
}

</style>
