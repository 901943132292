const en = {
  button: {
    resetPassword: "Reset password",
    submit: "Submit",
    continueWithEmail: "Continue with email",
    continueWithGoogle: "Continue with Google",
    continueWithApple: "Continue with Apple",
    signUpWithGoogle: 'Sign up with Google',
    startGifting: "Start gifting",
    selectEdit: "Select & Edit",
    createNewAccount: "Create new account",
    resendConfirmation: "Resend verification link",
    sendResetLink: "Send reset link",
    resend: "Resend",
    login: "Log in",
    continue: "Continue",
    continueWithPassword: "Continue with password",
    next: "Next",
    enter: "Enter"
  },
  shared: {
    platform: "Platform",
    sender: "Sender",
    recipient: "Recipient",
    expiredAt: "Expired at"
  },
  errors: {
    email: "Email is incorrect"
  },
  signUpCompletion: {
    title: "Sign up",
    heading: "You’re just minutes away from spreading joy to your employees and clients with thoughtful gifts!",
    headingCompany: "You’re just minutes away from spreading joy to your employees and clients with thoughtful gifts!",
    formTitle: "A few details to get started",
    formTitleCompany: "A few details to get started",
    usingOptionsTitle: "I will primarily use Gifted for",
    enterPhoneNumber: "Enter your phone number",
    enterValidPhone: "Please enter a valid phone number",
    placeholders: {
      firstName: "First name",
      lastName: "Last name",
      phoneNumber: "Phone number",
      companyName: "Company name",
      employeesNumber: "Number of employees",
    },
    buttons: {
      next: "Next",
      signUp: "Sign up",
    }
  },
  text: {
    subscribeNewsUpdates: "I would like to receive news and updates from Gifted",
    subscribeNewsUpdatesError: "Please select to receive news and updates from Gifted",
    verificationCodeResent: "A new code has been sent to your email",
    completeProfile: "Complete you profile",
    emailConfirmation: "Email confirmation",
    weJustSentVerification: "We just sent you a verification email.",
    checkInboxConfirm:
      "Please <b>check your inbox</b> and click on the verification link to activate your account",
    checkInboxResetPassword:
      "Check your inbox <br/>We've just sent a link to reset your password.",
    didntReceive: "Didn't receive it?",

    privacyPolicy:
      "By clicking “Continue with Google/Email” above, you acknowledge that you have read and understood, and agree to <a href='https://gifted.co/terms-and-conditions' target='_blank'>Terms & Conditions</a> and <a href='https://gifted.co/privacy-policy' target='_blank'>Privacy Policy</a>",
    forgotPassword: "Forgot password ?",
    login: "Login",
    signUp: "Sign up",
    saveMeFor30Days: "Don’t ask me again for 30 days",
    createNewPlatform: "Create a new platform",
    createNewSupplier: "Create a new supplier",
    checkInbox: "Check your inbox for the link to reset your password",
    back: "Back",
    resetPassword: "Reset password",
    logout: "Logout",
    typeSystemTitle: "Recognition platform",
    typeSupplierTitle: "Supplier platform",
    accounts: "Accounts",
    signOut: "Sign out",
    step: "Step",
    addAccount: "Add account",
    userAlreadyRegistered: "It looks like a user with these credentials already exists. Log in instead?",
    enterVerificationCode: "Enter the 6 digit verification code sent to {sentTo}",
    confirmEmail:
      "We just sent you a verification email.<br/> Please <b class='text-bold'>check your inbox</b> and click on the verification link to activate your account.",
    riskifiedEmailFirstSubtitle:
      "Looks like you signed up with a personal email address <br> {email}",
    riskifiedEmailSecondSubtitle:
      "To ensure all of our gifting platform features are <br> enabled, please use a work email address - <br> {example}",
    wrongSecretCode:
      "Wrong secret code.<br> Please try again ({wrongAttepmtsCount} of {wrongAttepmtsLimit} attempts left)",
    signUpSubtitleText: {
      default: "Create your Gifted account or simply ",
      checkPass: "Now, set your password",
      checkUserData: "Tell us a little about yourself",
      companyData: "Tell us a little about your company",
      verificationRequired: "Verification required",
    },
    enterCode: "Enter code",
    sendNewCode: "Send a new code",
    enterCodeSentTo: "Enter verification code<br/><br/> A verification code was sent to <b>{email}</b> and will expire after 15 minutes",
    didNotReceiveCode: "Didn’t receive the code?",
    resendHyphen: "Re-send",
    riskifiedContinue: "I understand, but would like to use my personal email",
    enteredPersonalEmail: "Looks like you entered a personal email address.",
    useWorkEmailAddress: "To ensure all our features are enabled, please use a work email address.",
    signUpLoginTitle: "login",
    orMaybeLoginFooter: "or maybe you just want to",
    createGiftAccount: "create your Gifted account?",
    addANewGiftingAccount: "Add a new gifting account",
    letsCreateYourProfile: "Let's create your profile",
    passwordRules: "Your password must be at least 8 characters long include at least one capital letter, and one number",
    completeProfileSubtitle: "Great! You are almost there.",
    companyInformation: "Tell us a little about your company",
    backToLogIn: "Back to log in",
    termsLabels:
      "<label for='{label}'>I agree to the </label> <a href='https://gifted.co/terms-and-conditions' target='_blank'>Terms & Conditions</a> <label for='{label}'> and </label> <a href='https://gifted.co/privacy-policy' target='_blank'>Privacy Policy</a>",
    search: "Search",
    createPurposeTitle: "Who will you be sending these gifts to?",
    signUoCompanyCreationPurposeTitle: "Who will you be sending these gifts to?",
    passwordValidation: {
      characters: "At least 8 characters",
      capitalLetter: "At least one capital letter",
      number: "At least one number",
      match: "Passwords don't match",
      symbols: "Only latin letters, numbers and special symbols are allowed. No spaces."
    },
    creationPurposes: {
      myTeam: "My team",
      customers: "Customers",
      salesProspects: "Sales prospects"
    },
    acceptTerms: "Please agree to the Terms & Conditions and Privacy Policy to proceed",
    choosePurpose: "Please select a primary use for Gifted from the options provided",
    pleaseEnterCode: "Please enter the code to proceed",
    enteredCodeIsIncorrect: "Oops! The entered code is incorrect. Please double-check and try again",
    continueWithOkta: "Continue with Okta",
  },
  title: {
    signIn: "Gifting Platform | Sign In",
    signUp: "Gifting Platform | Sign Up",
    dashboard: "Gifting Platform | Dashboard",
    createNewPlatform: "Gifting Platform | Create a new platform",
    createNewSupplier: "Gifting Platform | Create a new supplier"
  },
  placeholder: {
    enterEmailAddress: "Enter your email address",
    enterPassword: "Enter password",
    enterSecretCode: "Enter secret code",
    confirmPassword: "Confirm password",
    enterCode: "Enter code",
    code: "Code",
    chooseCountry: "Choose country",
    firstName: "First name",
    enterYourWorkEmail: "Enter your work email",
    lastName: "Last name",
    phone: "Phone",
    phoneNumber: "Phone number",
    companyName: "Company name",
    companyEmployees: "Number of employees",
    workEmail: "Work email",
    password: 'Password',
  },
  header: {
    startNewPlatform: "Start a new platform",
    giftingPlatforms: "My gifting platforms",
    supplierPlatform: "Join as a gifting vendor",
    employeeRecognition: "Recognition platform"
  },
  info: {
    supplierPlatform: "Start selling your gift cards today",
    employeeRecognition: "Send the perfect gift for any occasion",
    emailNotConfirmed: "Please confirm your email to use platform!",
    emailConfirmed: "Your email was confirmed."
  },
  pageLabel: {
    "reset-password": "Reset password",
    "create-password": "Create password",
    "invite": "Create password"
  },
  expiredInvitation: {
    title: {
      expired: "Your invitation has expired",
      canceled: "Your invitation was canceled"
    },
    description: {
      expired: "Your invitation to join {systemName} platform has expired. The invitation was valid for 48 hours. You can request another invitation.",
      canceled: "Your invitation was canceled. You can request another invitation."
    },
    requestInvitation: "Request invitation"
  },
  platform: {
    title: "Choose an account",
    systemCreation: "Open a new account"
  }
};

export default en;
