import type {
    OktaParams,
    OktaLinkResponse,
    SocialCallbackResponse
} from "@/types/sign-in";

import { HTTP } from "@/main";

const OKTA_URL = "/auth/oauth/okta";
const OKTA_LINK_URL = "/auth/oauth/okta/url";

export const getOktaLink = (): Promise<OktaLinkResponse> => {
    return HTTP.get(OKTA_LINK_URL);
};

export const getUserInfoByOkta = (params: OktaParams): Promise<SocialCallbackResponse> => {
    return HTTP.get(OKTA_URL, { params });
};